.mykl-progress {
    background: transparent;
    min-height: 20px;
    height: 100%;
    max-height: 50px;
}

.progress-bar {
    position: relative;
    color: var(--primary);
    border: 3px solid currentColor;
    box-shadow: 0 0 20px inset currentColor;
    min-height: inherit;
    height: 100%;
    max-height: inherit;
}

.progress-value {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    padding: 0 10px;
}

.progress-primary .progress-bar {
    color: var(--primary);
}

.progress-secondary .progress-bar {
    color: var(--secondary);
}

.progress-success .progress-bar {
    color: var(--success);
}

.progress-danger .progress-bar {
    color: var(--danger);
}

.progress-warning .progress-bar {
    color: var(--warning);
}

.progress-info .progress-bar {
    color: var(--info);
}

.progress-light .progress-bar {
    color: var(--light);
}

.progress-dark .progress-bar {
    color: var(--dark);
}