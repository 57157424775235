.media-group .mykl-media + .mykl-media {
    border-top: 1px solid #F2F2F2;
}

.mykl-media {
    display: flex;
    align-items: center;
    position: relative;
}

.media-vertical > * {
    flex: 1;
}

.media-group > .mykl-media {
    padding: 10px;
}

.media-cover {
    position: relative;
    text-align: center;
    align-self: center;
    margin-right: 30px;
    border-radius: 5px;
    width: 220px;
    flex-shrink: 0;
}

.media-cover img {
    width: 100%;
}

.media-body {
    flex: 1;
    padding: 10px 0;
}

.media-body > *:last-child {
    margin-bottom: 0;
}

.mykl-media .mykl-tooltip {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 12px;
}

.media-cover .media-cover-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.media-vertical {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.media-vertical .media-cover {
    margin-right: 0;
    margin-bottom: 20px;
    min-width: 100%;
}
