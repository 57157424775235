/* reset */
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

button {
    border: none;
    background: none;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: #000;
}

/*  To Do Check */
:root {
    --primary: rgb(0, 76, 255);
    --secondary: dimgrey;
    --success: yellowgreen;
    --danger: indianred;
    --warning: coral;
    --info: #FFBB00;
    --light: #F2F2F2;
    --dark: #101010;
}

.blind {
    display: block;
    width: 1px;
    height: 1px;
    line-height: 0;
    text-indent: -9999px;
    margin: 0 !important;
}

.text-hidden {
    display: none;
}

a {
    text-decoration: none;
    color: #222222;
}