.mykl-range {
    position: relative;
    /*width: 100%;*/
    width: 400px;
    max-width: 100%;
}

.mykl-range .slider {
    position: relative;
    width: 100%;
    height: 10px;
    border-radius: 15px;
    margin-bottom: 20px;
    z-index: -3;
}

.mykl-range .slider .track {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 15px;
    background: #bdbdbd;
    z-index: -3;
}

.mykl-range .slider .range {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 25%;
    right: 25%;
    border-radius: 15px;
    background: var(--primary);
    z-index: -2;
}

.mykl-range .slider .thumb {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--primary);
}

.mykl-range .slider .thumb.left {
    top: 50%;
    transform: translateY(-50%);
}


.mykl-range .slider .thumb.right {
    top: 50%;
    transform: translateY(-50%);
}

.mykl-range .slider .thumb span {
    position: absolute;
    top: 100%;
}

.mykl-range input[type="range"] {
    position: absolute;
    /* opacity로 가린 것을 이벤트도 비활성화하기 위해 */
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    height: 10px;
    width: 100%;
    opacity: 0;
}

.mykl-range input[type="range"]::-ms-thumb {
    /* 겹쳐진 두 thumb를 모두 활성화 */
    pointer-events: all;
    width: 15px;
    height: 15px;
    border-radius: 0;
    border: 0 none;
    background-color: red;
    cursor: pointer;
    /* appearance를 해야 위의 스타일들을 볼 수 있음 */
    -webkit-appearance: none;
}

.mykl-range input[type="range"]::-moz-range-thumb {
    /* 겹쳐진 두 thumb를 모두 활성화 */
    pointer-events: all;
    width: 15px;
    height: 15px;
    border-radius: 0;
    border: 0 none;
    background-color: red;
    cursor: pointer;
    /* appearance를 해야 위의 스타일들을 볼 수 있음 */
    -webkit-appearance: none;
}

.mykl-range input[type="range"]::-webkit-slider-thumb {
    /* 겹쳐진 두 thumb를 모두 활성화 */
    pointer-events: all;
    width: 15px;
    height: 15px;
    border-radius: 0;
    border: 0 none;
    background-color: red;
    cursor: pointer;
    /* appearance를 해야 위의 스타일들을 볼 수 있음 */
    -webkit-appearance: none;
}

.mykl-range .step {
    position: absolute;
    display: block;
    width: 1px;
    height: 10px;
    background: antiquewhite;
    z-index: -1;
    transform: translateX(7.5px);
    /*z-index: 3;*/
}