.mykl-loading,
.loading {
    margin: 0 auto;
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 100%;
}

.mykl-loading.loading-stop {
    display: none;
}

.loading {
    border: 4px solid;
    border-color: var(--primary) var(--primary) transparent transparent;
    animation: rotate-loading 2s linear 0s infinite normal;
}

.loading-text {
    animation: loading-text-opacity 3s linear 0s infinite normal;
    font-size: 10px;
    font-weight: bold;
    opacity: 0;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 50%;
    width: 100%;
}

@keyframes rotate-loading {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg)}
}

@keyframes loading-text-opacity {
    0% { opacity: 0.4 }
    20% { opacity: 0.4 }
    50% { opacity: 1 }
    100% { opacity: 0.4}
}