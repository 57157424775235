.mykl-navbar {
    display: flex;
    align-items: center;
}

.mykl-navbar .nav-logo-cover {
    display: flex;
    align-items: center;
    margin-right: 50px;
}

.mykl-navbar .nav-logo-cover .nav-logo {
    margin-left: 10px;
}

.mykl-navbar .nav-logo {
    margin-right: 50px;
}

.mykl-navbar .nav-logo {
    margin-right: 0px;
}

.mykl-navbar .nav {
    vertical-align: middle;
}

.nav {
    display: flex;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
}

.nav > li {
    vertical-align: top;
    padding: 0 25px;
}

.nav > li:first-child {
    padding-left: 0;
}

.nav-end {
    margin-left: auto;
}

/*.nav-menu {*/
/*    border-top: 1px solid #000;*/
/*    display: none;*/
/*}*/

/*.nav-menu > li {*/
/*    padding: 5px 10px;*/
/*    border-bottom: 1px solid #ccc;*/
/*}*/

.nav-toggle {
    display: none;
}

@media all and (max-width: 938px) {
    .mykl-navbar {
        justify-content: space-between;
    }

    .nav-toggle {
        display: block;
        padding: 5px;
    }

    .nav-hamburger {
        position: relative;
        display: block;
        width: 10px;
        height: 2px;
        background: #000;
    }

    .nav-hamburger::before {
        content: "";
        display: block;
        width: 10px;
        height: 2px;
        background: #000;
        position: absolute;
        top: -4px;
        left: 0;
    }

    .nav-hamburger::after {
        content: "";
        display: block;
        width: 10px;
        height: 2px;
        background: #000;
        position: absolute;
        bottom: -4px;
        left: 0;
    }

    /*.mykl-navbar .nav {*/
    /*    display: none;*/
    /*    flex-basis: 100%;*/
    /*    flex-grow: 1;*/
    /*    align-items: center;*/
    /*    flex-direction: column;*/
    /*}*/

    /*.mykl-navbar .nav.show {*/
    /*    display: block;*/
    /*}*/
}