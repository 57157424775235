.mykl-card {
    border-radius: 20px;
    position: relative;
}

.card-center {
    text-align: center;
}

.card-center .card-header {
    text-align: initial;
}

.mykl-card > * {
    padding: 10px;
    border-bottom: 1px solid #343434;
}

.mykl-card > *:last-child {
    border-bottom: none;
}

.card-header {
    background: #bdbdbd;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.card-style-A .card-header {
    position: absolute;
    background: #fff;
    border: none;
    left: 20px;
    transform: translateY(-50%);
}

.card-row .card-body {
    display: flex;
}
/*.mykl-card .mykl-tooltip {*/
/*    position: absolute;*/
/*    top: 12px;*/
/*    left: 12px;*/
/*    font-size: 12px;*/
/*}*/
