.mykl-select {
    position: relative;
    display: inline-block;
    min-width: 80px;
}

.mykl-select select{
    display: none;
}

.mykl-select .select-group {
    position: relative;
    width: 100%;
}

.mykl-select .select-selected {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 11px 32px 11px 16px;
    border-radius: 5px;
    border: 1px solid #DFDFDF;
    text-align: left;
    font-weight: 500;
    font-size: 1rem;
    color: #101010;
    display: block;
}

.mykl-select .select-selected:before {
    content:"";
    position: absolute;
    right:16px;
    top:50%;
    transform: translateY(-50%) rotateZ(45deg);
    width: 8px;
    height: 2px;
    background: #707070;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
}

.mykl-select .select-selected:after {
    content:"";
    position: absolute;
    right: 11px;
    top:50%;
    transform: translateY(-50%) rotateZ(-45deg);
    width: 8px;
    height: 2px;
    background: #707070;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
}

.mykl-select .select-selected.select-arrow-active:before {
    transform: translateY(-50%) rotateZ(-225deg);
}

.mykl-select .select-selected.select-arrow-active:after {
    transform: translateY(-50%) rotateZ(225deg);
}

.mykl-select .select-items div {
    color: #000;
    padding: 11px 32px 11px 16px;
    border: 1px solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    user-select: none;
    word-break: keep-all;
    white-space: nowrap;
}

.mykl-select .select-items {
    width: 100%;
    position: absolute;
    left: 0;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    z-index: 10;
    top: 100%;
    background-color: #fff;
}

.mykl-select .select-hide {
    visibility: hidden;
}

.mykl-select .select-items div:hover, .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

.mykl-select.select-sm .select-selected,
.mykl-select.select-sm .select-items div {
    padding: 8px 32px 8px 16px;
}