.mykl-check .check-input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.mykl-check .check-label {
    font-size: 1rem;
    display: inline-block;
    position: relative;
    left: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    padding-left: 1.5rem;
}

.mykl-check .check-label:before {
    width: 1rem;
    height: 1rem;
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    border: 1px solid rgba(189, 189, 189, 0.58);
    transform: translateY(-50%);
    box-sizing: border-box;
}

.mykl-check .check-input:checked + .check-label:before {
    background: var(--primary);
    border: 1px solid var(--primary);
}

.mykl-check .check-input:checked + .check-label:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 0.7rem;
    height: 0.35rem;
    border: 0.125rem solid;
    border-color: transparent transparent rgba(242, 242, 242, 0.9) rgba(242, 242, 242, 0.9);
    top: 40%;
    left: 0;
    transform: translate(20%, -50%) rotate(-45deg);
    transform-origin: 50%;
    box-sizing: border-box;
}

/* 상태 */
.mykl-check .check-input:disabled + .check-label {
    pointer-events: none;
    filter: none;
    opacity: .5;
}

.mykl-check .check-input[readonly] + .check-label {
    pointer-events: none;
    cursor: default;
}
