.mykl-tooltip {
    position: relative;
    display: inline-block;
}

.mykl-tooltip .tooltip-content {
    position: absolute;
    display: none;
    min-width: 200px;
    padding: 10px;
    bottom: -10px;
    left: 50%;
    transform: translateX(-20px) translateY(100%);
    background: #fff;
    z-index: 2;
    color: #555;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    opacity: 0;
}

.mykl-tooltip:hover .tooltip-icon::after, .mykl-tooltip:hover  .tooltip-content,
.mykl-tooltip:hover .tooltip-icon::after, .mykl-tooltip:focus  .tooltip-content,
.mykl-tooltip:focus .tooltip-icon::after, .mykl-tooltip:hover  .tooltip-content
.mykl-tooltip:focus .tooltip-icon::after, .mykl-tooltip:focus  .tooltip-content{
    display: block;
    opacity: 1;
}

.mykl-tooltip .tooltip-icon::after {
    display: none;
    content: ' ';
    position: absolute;
    border: 5px solid transparent;
    z-index: 2;
}

.tooltip-right .tooltip-icon::after {
    border-color:  transparent #fff transparent transparent;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
}

.tooltip-right .tooltip-content {
    top: 0;
    left: 100%;
    transform: translateX(10px) translateY(-10px);
}

.tooltip-left .tooltip-icon::after {
    border: 5px solid;
    border-color:  transparent transparent transparent #fff;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
}

.tooltip-left .tooltip-content {
    top: 0%;
    right: 100%;
    transform: translateX(-10px) translateY(-10px);
}

.tooltip-top .tooltip-icon::after {
    border-color:  #fff transparent transparent transparent;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip-top .tooltip-content {
    top: -10px;
    left: 50%;
    transform: translateX(-20px) translateY(-100%);
}

.tooltip-bottom .tooltip-icon::after {
    border-color:  transparent transparent #fff transparent;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip-bottom .tooltip-content {
    bottom: -10px;
    left: 50%;
    transform: translateX(-20px) translateY(100%);
}
