.mykl-radio {
    position: relative;
    vertical-align: top;
}

.mykl-radio .radio-input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.mykl-radio .radio-label {
    display: inline-block;
    position: relative;
    left: 0;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    padding-left: 1.5rem;
}

.mykl-radio .radio-label:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 1rem;
    height: 1rem;
    border: 0.0625rem solid rgba(189, 189, 189, 0.58);
    border-radius: 100%;
    transform: translateY(-50%);
    box-sizing: border-box;
}

.mykl-radio .radio-input:checked + .radio-label:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 1rem;
    height: 1rem;
    background: var(--primary);
    border-radius: 100%;
    border: 0.15rem solid #fff;
    transform: translate(0, -50%);
    box-sizing: border-box;
}

/* 상태 */
.mykl-radio .radio-input:disabled + .radio-label {
    pointer-events: none;
    filter: none;
    opacity: .5;
}

.mykl-radio .radio-input[readonly] + .radio-label {
    pointer-events: none;
    cursor: default;
}
