.tab-title {
    display: inline-block;
    font-size: 0.9rem;
    color: #fff;
    padding-left: 20px;
}

.tab-menu {
    font-size: 0;
}

.tab-title,
.tab-item {
    display: inline-block;
    border-radius: 3px;
    padding: 10px 20px;
    font-size: 0.875rem;
    color: #101010;
    position: relative;
    box-sizing: border-box;
    text-align: center;
}

.tab-item * {
    pointer-events: none;
}

.tab-item.active {
    font-weight: 600;
    background: var(--primary);
    color: #fff;
}

.tab-title > span,
.tab-item > span {
    display: inline-block;
    line-height: normal;
}

.tab-title:before,
.tab-item:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

.tab-title:after,
.tab-item:after {
    content:'';
    display: block;
}

.tab-content {
    display: none;
    padding: 10px;
    border-top: 1px solid #bdbdbd;
    box-sizing: border-box;
}

.tab-content.show {
    display: block;
}

.tab-content.mykl-tab {
    padding: 0;
    border: none;
}

/* 상태 */
.tab-secondary .tab-item.active {
    background: var(--secondary);
}

.tab-success .tab-item.active {
    background: var(--success);
}

.tab-danger .tab-item.active {
    background: var(--danger);
}

.tab-warning .tab-item.active {
    background: var(--warning);
}

.tab-info .tab-item.active {
    background: var(--info);
}

.tab-light .tab-item.active {
    background: var(--light);
    color: #101010;
}

.tab-dark .tab-item.active {
    background: var(--dark);
}

/* disabled */
.tab-item.tab-disabled {
    pointer-events: none;
    color: var(--light );
}

/* nav 반대 */
.tab-rev .tab-menu {
    text-align: end;
}

/* 둥글게 */
.tab-round .tab-item {
    border-radius: 20px;
}

.tab-round .tab-item + .tab-item {
    margin-left: 10px;
}

.tab-round .tab-content {
    border-top: none;
}

/* 크기 */
.tab-lg > nav > .tab-title, .tab-lg > nav > .tab-item {
    width: 220px;
    height: 60px;
}

.tab-lg.tab-vertical > .tab-content.show {
    width: calc(100% - 220px);
}

/* 세로 정렬 */
.tab-vertical {
    display: flex;
}
.tab-vertical > .tab-menu {
    flex: 0 0 120px;
}

.tab-vertical > nav > .tab-title,
.tab-vertical > nav > .tab-item {
    display: flex;
    align-items: center;
    border: none;
    border-top: 1px solid #F2F2F2;
    border-radius: 0;
    text-align: left;
}

.tab-vertical > .tab-content {
    display: none;
    border-top: none;
    border-left: 1px solid #B0B0B0;
}

.tab-vertical > .tab-content.show.mykl-tab {
    border-left: none;
    display: flex;
}

.tab-vertical > .tab-content.show {
    display: inline-block;
    width: 100%;
}


/*@media all and (max-width: 768px) {*/
/*    .tab {*/
/*        display: flex;*/
/*    }*/

/*    .tab-item {*/
/*        flex: 1;*/
/*        flex-shrink: 0;*/
/*        overflow: hidden;*/
/*        white-space: nowrap;*/
/*        text-overflow: ellipsis;*/
/*    }*/

/*    .tab-item.active {*/
/*        min-width: fit-content;*/
/*        text-overflow: initial;*/
/*    }*/
/*}*/