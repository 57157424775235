.mykl-tag {
    font-size: 13px;
}

.tag-input-box {
    position: relative;
}

.tag-input-box::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    content: '#';
    color: #000;
}

.tag-input-box.inactive::before {
    /*content: none;*/
    color: #b7b7b7;
}

.tag-input {
    font-size: 13px;
    border: none;
    padding-left: 8px;
    width: 200px;
    vertical-align: middle;
    box-sizing: content-box;
}

/* ie */
.tag-input::-ms-clear {
    display: none;
}

.tag-input-box.focus {
    display: inline-block;
    width: auto;
    padding: 0 9px;
    border-radius: 4px;
    line-height: 30px;
    box-sizing: border-box;
    background: #eff0f2;
    color: #000;
    vertical-align: top;
}

.tag-input:focus {
    outline: none;
}

.tag-input-box.focus .tag-input {
    background: #eff0f2;
    width: auto;
}

.tag-input-box .tag-hidden-text {
    display: inline-block;
    visibility: hidden;
    position: fixed;
    overflow:auto;
    min-width: 9px;
    width: auto;
}

.tag-item {
    display: inline-block;
    margin: 0 10px 0 0;
}

.tag-item .tag-text {
    display: block;
    height: 30px;
    padding: 0 9px;
    border-radius: 4px;
    line-height: 32px;
    background: #eff0f2;
    color: #000;
    font-style: normal;
}


.mykl-tag .mykl-tooltip {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
}

.mykl-tag .mykl-tooltip.tooltip-active {
    display: inline-block;
}

.mykl-tag .mykl-tooltip.tooltip-active .tooltip-icon::after,
.mykl-tag .mykl-tooltip.tooltip-active .tooltip-content {
    display: block;
    opacity: 1;
}

