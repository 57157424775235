.dropdown-toggle {
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
    color: #101010;
    /*white-space: nowrap;*/
}

.dropdown-toggle:hover {
    color: #222;
}

.dropdown-menu-outer {
    position: absolute;
    height: 0;
    overflow: hidden;
    box-sizing:border-box;
    padding-top: 8px;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
}

.dropdown-menu {
    border-radius:5px;
    background: #fff;
    height: auto;
    padding:20px 32px 20px 20px;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    overflow: hidden;
    transform: matrix(1,0,0,1,0,0);
}

.dropdown-toggle:hover + .dropdown-menu-outer, .dropdown-menu-outer:hover {
    overflow: visible;
    height: auto;
    opacity: 1;
    z-index: 10;
}

.dropdown-item + .dropdown-item {
    margin-top: 35px;
}

.dropdown-text:hover {
    font-weight: 600;
    color: #004CFF;
}

.dropdown-text {
    display: block;
    font-weight: 500;
    font-size: 14px;
    color: #505050;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
}

.dropdown-list {
    float: left;
    display: inline-block;
    width: 100%;
}