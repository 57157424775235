.modal-dialog {
    display: none;
}
.modal-dialog.show {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.5);
}

.modal-content {
    margin-bottom: 100px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
}

.modal-header {
    padding: 5px 10px;
    position: relative;
}

.modal-body {
    padding: 5px 10px;
}

.modal-footer {
    padding: 5px 10px;
}

.btn-close {
    border: none;
    position: absolute;
    background: none;
    top: 50%;
    right: 0px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
}

.btn-close::before {
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    width: 16px;
    height: 2px;
    display: block;
    background: #000;
    transform: translateY(-1px) rotate(45deg) ;
    transform-origin: 50%;
}

.btn-close::after {
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    width: 16px;
    height: 2px;
    display: block;
    background: #000;
    transform: translateY(-1px) rotate(-45deg);
    transform-origin: 50%;
}

