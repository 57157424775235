/* 위치 */
.text-start {
    text-align: left !important;
    text-align: -moz-left !important;
    text-align: -webkit-left !important;
}

.text-center {
    text-align: center !important;
    text-align: -moz-center !important;
    text-align: -webkit-center !important;
}

.text-end {
    text-align: right !important;
    text-align: -moz-right !important;
    text-align: -webkit-right !important;
}

/* 글자 높이 간격 */
.lh-sm {
    line-height: 1.25;
}

.lh-md {
    line-height: 1.5;
}

.lh-lg {
    line-height: 2;
}

/* 글자 색상 */
.text-primary {
    color: var(--primary);
}

.text-secondary {
    color: var(--secondary);
}

.text-success {
    color: var(--success);
}

.text-danger {
    color: var(--danger);
}

.text-warning {
    color: var(--warning);
}

.text-info {
    color: var(--info);
}

.text-light {
    color: var(--light);
}

.text-dark {
    color: var(--dark);
}

/* 글자 크기 */
.fs-1 {
    font-size: 2.5rem;
}

.fs-2 {
    font-size: 2rem;
}

.fs-3 {
    font-size: 1.75rem;
}

.fs-4 {
    font-size: 1.5rem;
}

.fs-5 {
    font-size: 1.25rem;
}

.fs-6 {
    font-size: 1rem;
}

.fw-bold {
    font-weight: 700;
}

.fw-bolder {
    font-weight: bolder;
}

.fw-semibold {
    font-weight: 600;
}

.fw-normal {
    font-weight: 400;
}

.fw-light {
    font-weight: 300;
}

.fw-lighter {
    font-weight: lighter;
}

.fst-italic {
    font-style: italic;
}

.bg-primary {
    background: var(--primary);
}

.bg-secondary {
    background: var(--secondary);
}

.bg-success {
    background: var(--success);
}

.bg-danger {
    background: var(--danger);
}

.bg-warning {
    background: var(--warning);
}

.bg-info {
    background: var(--info);
}

.bg-light {
    background: var(--light);
}

.bg-dark {
    background: var(--dark);
}
