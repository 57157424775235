.mykl-textarea {
    border: 1px solid #DFDFDF;
    padding: 18px 20px;
    resize: none;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    height: 300px;
}

.mykl-textarea.textarea-vertical {
    resize: vertical;
}

.mykl-textarea.textarea-horizontal {
    resize: horizontal;
}

.mykl-textarea.textarea-auto {
    resize: both;
}

.mykl-textarea.textarea-smart {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.mykl-textarea.textarea-smart::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}

