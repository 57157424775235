.mykl-emotion {
    position: relative;
}

.mykl-emotion .remove-emotion {
    position: absolute;
    top: 0;
}

.mykl-emotion .emotion-group {
    display: none;
}
.mykl-emotion .btn-reset {
    margin-left: 10px;
}

.mykl-emotion .emotion-group.show {
    display: block;
}

.mykl-emotion .emotion-item {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    vertical-align: middle;
    text-indent: -9999px;
}

.mykl-emotion .emotion-group .emotion-item:hover {
    background-color: #bdbdbd;
}

.emotion-good {
    background: url("../../images/emoji-smile.svg") no-repeat center;
}
.emotion-sad {
    background: url("../../images/emoji-frown.svg") no-repeat center;
}
.emotion-like {
    background: url("../../images/emoji-heart-eyes.svg") no-repeat center;
}
.emotion-bad {
    background: url("../../images/emoji-angry.svg") no-repeat center;
}
.emotion-thumbsUp {
    background: url("../../images/hand-thumbs-up.svg") no-repeat center;
}
.emotion-thumbsDown {
    background: url("../../images/hand-thumbs-down.svg") no-repeat center;
}