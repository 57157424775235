.mykl-time .time-input {
    display: none;
}

.mykl-time .time-wrapper {
    display: none;
    position: relative;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 1);
}

.mykl-time .time-wrapper.show {
    display: flex;
    height: 150px;
    width: max-content;
    position: relative;
}

.mykl-time .day-night > * {
    display: block;
}

.mykl-time .time-box {
    display: inline-block;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
}

.mykl-time .time-group {
    margin: 2px;
    cursor: pointer;
}

.mykl-time .time-mark {
    background: dodgerblue;
}