.form-group {
    position: relative;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.form-group > * {
    margin: 0 0 12px 0;
}

.form-group .form-label {
    font-weight: 600;
    margin: 0 0 12px 0;
}

.form-row {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.form-row > * {
    margin: 0 12px 0 0;
}

.form-row > *:last-child {
    margin: 0;
}

.form-row .form-label {
    margin: 0;
}

.form-group .form-label {
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 80px;
    width: 100px;
}

/*.form-group > .mykl-input {*/
/*    flex-shrink: 1;*/
/*}*/
