.mykl-btn {
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9375rem;
    font-weight: 600;
    box-sizing: border-box;
    min-width: 80px;

    /*!* 한글, 영어 높이 동일 하게 됨*!*/
    line-height: 2;
    border: 1px solid transparent;
    background: #fff;
    padding: 5px 15px;
}

.mykl-btn:hover {
    opacity: 0.8;
}

/* 크기 */
.btn-sm {
    min-width: 50px;
}

.btn-lg {
    min-width: 140px;
}

.btn-round {
    border-radius: 28px;
}

.btn-full {
    width: 100%;
    max-width: initial;
}

/* 상태 */
.btn-primary {
    background: var(--primary);
    color: #fff;
}

.btn-primary-rev {
    border: 1px solid currentColor;
    color: #004CFF;
}

.btn-secondary {
    border: 1px solid transparent;
    background: var(--secondary);
    color: #505050;
}

.btn-secondary-rev {
    color: #505050;
    border: 1px solid var(--secondary);
    background: #fff;
}

.btn-dark {
    border: 1px solid #101010;
    background: #101010;
    color: #fff;
}

.btn-hide {
    display: none !important;
}


