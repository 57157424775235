.mykl-autoComplete .auto-complete.selected {
    background: #bdbdbd;
}

.mykl-autoComplete .auto-complete-list {
    position: absolute;
    background: antiquewhite;
    z-index: 1;
    display: none;
}

.mykl-autoComplete .auto-complete-item {
    position: relative;
}

.mykl-autoComplete .auto-complete-item.active .auto-complete-list.show {
    display: block;
}
