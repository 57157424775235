.mykl-table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
}

.mykl-table th {
    font-size: 1rem;
    font-weight: 600;
}

.mykl-table td {
    font-size: 0.9rem;
}

.mykl-table thead {
    text-align: center;
    background-color: var(--light);
}

.mykl-table thead th,
.mykl-table thead td {
    padding: 14px;
}

.mykl-table thead th, td {
    vertical-align: middle;
}

.mykl-table tbody th,
.mykl-table tbody td {
    padding: 10px;
    vertical-align: middle;
}

.mykl-table tbody th a,
.mykl-table tbody td a {
    color: inherit;
    font-weight: inherit;
    font-family: inherit;
    font-size: inherit;
}


.mykl-table tbody tr {
    border-top: 1px solid var(--light);
}

/* 상태 */
.table-primary thead {
    background: var(--primary);
    color: #fff;
}

.table-primary tbody tr {
    border-top: 1px solid var(--primary);
}

.table-secondary thead {
    background: var(--secondary);
    color: #fff;
}

.table-secondary tbody tr {
    border-top: 1px solid var(--secondary);
}

.table-success thead {
    background: var(--success);
}

.table-success tbody tr {
    border-top: 1px solid var(--success);
}

.table-danger thead {
    background: var(--danger);
    color: #fff;
}

.table-danger tbody tr {
    border-top: 1px solid var(--danger);
}

.table-warning thead {
    background: var(--warning);
}

.table-warning tbody tr {
    border-top: 1px solid var(--warning);
}

.table-info thead {
    background: var(--info);
}

.table-info tbody tr {
    border-top: 1px solid var(--info);
}

.table-dark thead {
    background: var(--dark);
    color: #fff;
}

.table-dark tbody tr {
    border-top: 1px solid var(--dark);
}

/*.mykl-table tbody .text-sm {*/
/*    font-size: 13px;*/
/*    line-height: 18px;*/
/*}*/

/*.mobile-collapse {*/
/*    visibility: collapse;*/
/*}*/
