
.mykl-img img {
    width: 100%;
    height: 100%;
}

.image-cover img {
    object-fit: cover;
}

.image-contain img {
    object-fit: contain;
}

.image-fill img {
    object-fit: fill;
}

figure.mykl-img {
    border: thin #c0c0c0 solid;
    padding: 5px;
    margin: auto 0;
}

figure.mykl-img > * {
    display: block;
}

figure.mykl-img img {
    height: calc(100% - 30px);
}

.mykl-img figcaption {
    display: inline-block;
    width: 100%;
    background-color: #222;
    color: #fff;
    font: italic smaller sans-serif;
    padding: 3px;
    min-height: 30px;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
}